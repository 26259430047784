import {
	getInvitationActivationPage,
	getNoTenantPath,
	getTenantCreationPage
} from './RoutesHelper';

export function getRedirectToUrl(pathname: string, userHasPendingInvitations: boolean): string {
	// when there's no tenant and users comes from the open invitation link,
	// take them to the open invitation activation page
	if (
		(userHasPendingInvitations && pathname !== getTenantCreationPage()) ||
		pathname.includes(getInvitationActivationPage())
	) {
		return getInvitationActivationPage();
	}
	if (!userHasPendingInvitations) {
		return getNoTenantPath();
	}
	return getTenantCreationPage();
}
