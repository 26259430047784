import { ProductType, TenantProduct } from 'src/types/Tenant';

import { Logger } from '../Logger';
import { API_URL_PATHS } from '../server/ApiUrlPaths';
import { apiCall } from '../server/ServerApi';

const { CENTRAL_CONFIG_PATH } = API_URL_PATHS;
export type TConfiguration = Record<string, Record<string, unknown>>;
const getProductHubProductID = (products: TenantProduct[]): string => {
	if (!products) {
		return null;
	}
	return products.find((product: TenantProduct) => product.type === ProductType.SCANNER)?.id;
};

// todo: replace SITE_SCANNER_UI + ProductType.SCANNER with hub product
export const getCentralConfig = async (products: TenantProduct[]): Promise<TConfiguration> => {
	const scannerProductID = getProductHubProductID(products);
	if (scannerProductID) {
		try {
			const configurationResult: { SITE_SCANNER_UI: TConfiguration } = await apiCall(
				'GET',
				`${CENTRAL_CONFIG_PATH}?productId=${scannerProductID}&subPath=SITE_SCANNER_UI`,
				{ isConfigUrl: true }
			);

			return configurationResult?.SITE_SCANNER_UI;
		} catch (error) {
			Logger.error('Error fetching central configuration', error);
			return null;
		}
	}
	return null;
};
