import { ServiceAccountDescriptor } from '../types/ServiceAccountDescriptor';
import { ServiceAccount } from '../types/ServiceAccountDetails';

import { API_URL_PATHS } from './server/ApiUrlPaths';
import { callGETAndCache } from './server/ServerCacheApi';

const { SERVICE_ACCOUNTS_URL_PATH } = API_URL_PATHS;

async function getServiceAccount(): Promise<ServiceAccountDescriptor> {
	const serviceAccounts = await callGETAndCache(SERVICE_ACCOUNTS_URL_PATH);
	// assuming there's only 1 service account per user
	return serviceAccounts[0];
}

export const getServiceAccountDetails = async (): Promise<ServiceAccount> => {
	const serviceAccount = await getServiceAccount();
	return callGETAndCache(`${SERVICE_ACCOUNTS_URL_PATH}/${serviceAccount.id}`);
};
