import { getActiveUsers, getUserManagementPagePath } from '../../helpers/RoutesHelper';

export enum USER_MANAGEMENT_PAGES {
	ACTIVE_USERS = 'activeUsers',
	PENDING = 'pending'
}

export const MANAGE_AND_INVITE_USERS_TABS = [
	{
		key: USER_MANAGEMENT_PAGES.ACTIVE_USERS,
		label: 'Active Users',
		route: getActiveUsers()
	},
	{
		key: USER_MANAGEMENT_PAGES.PENDING,
		label: 'Pending',
		route: getUserManagementPagePath()
	}
];
