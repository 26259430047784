import React, { FC } from 'react';

import { getErrorMessage } from '../../helpers/ApiErrorHelper';
import { IServerErrors } from '../../services/server/ServerError';

interface IInvitationFailedMessageProps {
	serverErrors: IServerErrors[];
	successRate: number;
}

export const InvitationFailedMessage: FC<IInvitationFailedMessageProps> = ({
	serverErrors,
	successRate
}) => {
	const renderErrMsg = (serverErrorMsg: string): JSX.Element => (
		<div className="error-details">{serverErrorMsg}</div>
	);

	const renderServerErrorsDetails = (serverErrors: IServerErrors[]): JSX.Element => {
		const serverErrorMessages = [];
		serverErrors.forEach((serverErr) => {
			const serverErrorMessage = getErrorMessage(serverErr.serverError);
			serverErrorMessages.push(`${serverErr.prefixMsg} (${serverErrorMessage})`);
		});
		return (
			<>
				{serverErrorMessages
					.filter((msg) => msg)
					.map((serverErrorMsg) => {
						return renderErrMsg(serverErrorMsg);
					})}
			</>
		);
	};

	const renderFailedMessage = (): JSX.Element => {
		const promptMessage = 'Please ensure the email addresses are valid and spelled correctly.';
		const preErrorsMsg = 'The following invites were not sent:';
		const summaryMessage = 'All other invites were sent successfully.';
		return (
			<div className="bulk-invitation-failure-msg">
				<p>{promptMessage}</p>
				<p>{preErrorsMsg}</p>
				<p>{renderServerErrorsDetails(serverErrors)}</p>
				{successRate > 0 && <p>{summaryMessage}</p>}
			</div>
		);
	};

	return <div className="invitations-failed-message">{renderFailedMessage()}</div>;
};
