import React, { FC } from 'react';
import { useHistory } from 'react-router';

import { getUnauthorized403Page } from '../helpers/RoutesHelper';
import { useUserTenant } from '../providers/userTenantProvider/UserTenantProvider';

export const withLimitedAccess = (WrappedComponent: FC): FC => {
	return (props): JSX.Element => {
		const { hasAdminPermissions } = useUserTenant();
		const history = useHistory();
		if (!hasAdminPermissions()) {
			history.replace(getUnauthorized403Page());
			return null;
		}

		return <WrappedComponent {...props} />;
	};
};
