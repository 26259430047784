import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { EvMainTitle, EvSpinner } from '@evinced-private/ui-common';

import isEqual from 'lodash/isEqual';

import { createHeadersOutOfTableColumns } from '../../app-tools/csv-wizard';
import { DownloadCSV } from '../../components/common/download-csv/DownloadCSV';
import {
	getColumnsFormatters,
	getRegisteredUsersTableColumns
} from '../../components/common/helpers/RegisteredUsersHelper';
import { RegisteredUsersTable } from '../../components/registered-users-table/RegisteredUsersTable';
import { UserInvitationPopup } from '../../components/user-invitation-popup/UserInvitationPopup';
import { UsersInvitations } from '../../components/users-invitations/UsersInvitations';
import { UsersTableFilters } from '../../components/users-table-filters/UsersTableFilters';
import { getAllStatusOptions, getAllUserTypes, IFilterOptions } from '../../helpers/FiltersHelper';
import { getUserManagementPagePath } from '../../helpers/RoutesHelper';
import { withLimitedAccess } from '../../hoc/withLimitedAccess';
import { useNotifications } from '../../providers/notificationsProvider/NotificationsConsumer';
import { INotificationsContext } from '../../providers/notificationsProvider/NotificationsContext';
import {
	ITableStateValue,
	useTableState
} from '../../providers/tableStateProvider/TableStateProvider';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import { getAllUsers } from '../../services/UsersService';
import { IUserToTenant } from '../../types/User';

import './RegisteredUsersPage.scss';

const TABLE_ID = 'registered-users-table';
const csvFileName = `users-data-${Date.now()}.csv`;
const formatDateColumns = ['createdTime', 'lastLogin'];
const maxRowsToExport = 10000;

const RegisteredUsersPageInner: FC = () => {
	const [isLoading, setLoading] = useState<boolean>(true);
	const [reloadTable, setReloadTable] = useState<boolean>(false);
	const [registeredUsers, setRegisteredUsers] = useState<IUserToTenant[]>([]);
	const [allRegisteredUsers, setAllRegisteredUsers] = useState<IUserToTenant[]>(null);
	const [filterValues, setFilterValues] = useState<IFilterOptions>(null);
	const [totalCount, setTotalCount] = useState<number>(0);
	/** This state is needed to avoid calling the getRegistered users list when the
	 * table state is not changed since the last time the getRegisteredUsersList was called
	 * TO DO: find a solution that will work with this state.
	 */
	const [lastTableState, setLastTableState] = useState<ITableStateValue>(null);

	const [invitationPopupState, setInvitationPopupState] = useState<{
		isOpened: boolean;
		invitationId: string;
	}>();

	const notificationsContext: INotificationsContext = useNotifications();

	const { tenant, usersRolesList } = useUserTenant();
	const history = useHistory();

	const filterOptions = useMemo(() => {
		const statusOptions = getAllStatusOptions();
		return {
			active: statusOptions,
			userType: getAllUserTypes(usersRolesList),
			freeSearch: ''
		};
	}, [usersRolesList]);

	const { getTableStateById, onFilterChange } = useTableState();
	const tableState = getTableStateById(TABLE_ID);

	const handleFilterChange = useCallback(
		(filters: IFilterOptions) => {
			if (!isEqual(filters, tableState.filters)) {
				onFilterChange(TABLE_ID, filters);
			}
		},
		[tableState.filters, onFilterChange]
	);

	const getSortByColumn = useCallback((): string => {
		return lastTableState?.tableSort?.[0]?.dataField;
	}, [lastTableState]);

	const onFiltersApply = useCallback(
		(currentFilters: IFilterOptions): void => {
			setFilterValues(currentFilters);
			handleFilterChange(currentFilters);
		},
		[setFilterValues, handleFilterChange]
	);

	const onClear = (): void => {
		setFilterValues(null);
		handleFilterChange(null);
	};

	useEffect(() => {
		if (!isEqual(tableState?.filters, filterValues?.filters)) {
			setFilterValues(tableState?.filters as IFilterOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!isEqual(lastTableState, tableState)) {
			setLastTableState(tableState);
		}
	}, [tableState, lastTableState, setLastTableState]);

	const getRegisteredUsersData = useCallback(
		async (allData?: boolean): Promise<void> => {
			try {
				const { paginationInfo, tableSort } = lastTableState || {};
				const pageParams = allData ? { page: 0, sizePerPage: maxRowsToExport } : paginationInfo;
				const paginationParams = {
					...pageParams,
					sortField: tableSort?.[0]?.dataField,
					sortOrder: tableSort?.[0]?.order
				};
				const response = await getAllUsers(filterValues, filterOptions, paginationParams);

				if (response) {
					const { list, overallTotal } = response;
					if (allData) {
						setAllRegisteredUsers(list);
					} else {
						setRegisteredUsers(list);
					}
					setTotalCount(overallTotal);
				}
			} catch (e) {
				notificationsContext.alert({
					errorMessage: 'Error getting registered users',
					serverError: e
				});
			}
		},
		[filterOptions, filterValues, lastTableState, notificationsContext]
	);

	useEffect(() => {
		const getRegisteredUsers = async (): Promise<void> => {
			setLoading(true);
			try {
				await getRegisteredUsersData();
			} catch (e) {
				notificationsContext.alert({
					errorMessage: 'Error getting registered users',
					serverError: e
				});
			} finally {
				setLoading(false);
			}
		};

		if (filterValues || lastTableState) {
			getRegisteredUsers();
		}
	}, [lastTableState, filterValues, notificationsContext, reloadTable, getRegisteredUsersData]);

	const registeredUsersPageContent = (): JSX.Element => {
		return isLoading ? (
			<EvSpinner />
		) : (
			<>
				<div className="filter-line">
					<UsersTableFilters
						filterOptions={filterOptions}
						filterValues={filterValues}
						onApply={onFiltersApply}
						onClear={onClear}
					/>
					<DownloadCSV
						csvFileName={csvFileName}
						reportHeaders={[...createHeadersOutOfTableColumns(getRegisteredUsersTableColumns())]}
						sortByColumn={getSortByColumn()}
						data={allRegisteredUsers}
						initDataOnClick={() => getRegisteredUsersData(true)}
						formatters={getColumnsFormatters()}
						formatDateColumns={formatDateColumns}
					/>
				</div>
				<RegisteredUsersTable
					registeredUsers={registeredUsers}
					totalCount={totalCount}
					tableId={TABLE_ID}
					isTableLoading={isLoading}
					reloadTable={() => setReloadTable(true)}
				/>
				<UserInvitationPopup
					isOpen={invitationPopupState?.isOpened}
					onClose={() => setInvitationPopupState(null)}
					invitationId={invitationPopupState?.invitationId}
					tenantId={tenant.id}
				/>
			</>
		);
	};

	return (
		<div className="registered-users-page">
			<div className="active-users-title">
				<EvMainTitle className="title">Active Users</EvMainTitle>
				<UsersInvitations
					onAfterBulkInvitationAction={() => history.push(getUserManagementPagePath())}
				/>
			</div>

			{registeredUsersPageContent()}
		</div>
	);
};

export const RegisteredUsersPage = withLimitedAccess(RegisteredUsersPageInner);
