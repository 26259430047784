import ReactGA, { EventArgs } from 'react-ga';

import { getUserFromLocalStorage, setGoogleAnalyticsUser } from '../../api/LocalStorageApi';
import { Logger } from '../Logger';

interface IHasUserId {
	userId: string;
}

const analyticsCode = process.env.GA_CODE;

let analyticsInitialized = false;
let currentUser = null;

export function initAnalytics(): void {
	try {
		ReactGA.initialize(analyticsCode);
		// Once we have a user ID we should init it here: ReactGA.set({ userId });
		const user = getUserFromLocalStorage();
		if (user) {
			const { userId } = user;
			ReactGA.set({ userId });
		}
		analyticsInitialized = true;
		Logger.debug('analytics was initialized');
	} catch (err) {
		Logger.error('Could not initialize analytics service. Got error:', err);
	}
}

export function fireEvent(args: EventArgs): void {
	if (!analyticsInitialized) {
		Logger.warn('Couldnt fire analytics event, analytics initialization failed.');
		throw new Error('Analytics service was called without initialization');
	}
	ReactGA.event(args);
}

export function pageView(path): void {
	if (!analyticsInitialized) {
		Logger.error('Couldnt call pageview, analytics initialization failed.');
		throw new Error('Analytics service was called without initialization');
	}
	ReactGA.pageview(path);
}

export function setCurrentUser(user: IHasUserId): void {
	currentUser = user;
	const { userId } = currentUser;
	setGoogleAnalyticsUser(currentUser);
	ReactGA.set({ userId });
}
