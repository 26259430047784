import { fireEvent } from '../analytics/AnalyticsService';
import { GA_ACTIONS, GA_CATEGORIES } from '../analytics/GoogleAnalyticsCategories';

export class FileHelper {
	static downloadFileFromUrl(url: string, label: string): void {
		const a = document.createElement('a');
		document.body.appendChild(a);
		a.style.display = 'none';
		a.href = url;
		a.click();
		window.URL.revokeObjectURL(url);
		fireEvent({
			category: GA_CATEGORIES.APPLICATIONS,
			action: GA_ACTIONS.DOWNLOAD,
			label
		});
	}
}
