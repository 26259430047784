import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { EvMainTitle, EvSection, EvSpinner } from '@evinced-private/ui-common';

import { useConfiguration } from 'src/providers/configurationProvider/ConfigurationProvider';

import { ProductCard } from '../../components/product-card/ProductCard';
import { IProductCardData, newProducts, ProductCardsData } from '../../consts/products-consts';
import {
	getProductToRedirectFromUrl,
	REDIRECT_TO_PRODUCT_VALUE
} from '../../helpers/InvitationTokenHelper';
import { getFlowAnalyzerForMobilePath, getMobileSDKPath } from '../../helpers/RoutesHelper';
import { ProductHubToggles } from '../../services/LocalTogglesService';
import { Logger } from '../../services/Logger';
import { getProductPlanDisplayName } from '../../services/ProductPlansService';
import {
	getAllProductsExpirationDetails,
	getUserTenant,
	TenantExpirationDetails
} from '../../services/TenantsService';
import { ProductType } from '../../types/Tenant';

import './WelcomePage.scss';

type IPrimaryButtonMessageData = {
	[product in ProductType]: string;
};
export const WelcomePage: FC = () => {
	const history = useHistory();
	const { getToggle } = useConfiguration();
	const [isLoading, setLoading] = useState(true);
	const [productExpirationDetails, setProductExpirationDetails] =
		useState<Map<ProductType, TenantExpirationDetails>>();
	const [primaryButtonMessageData, setPrimaryButtonMessageData] =
		useState<IPrimaryButtonMessageData>();

	const showNewProducts = getToggle(ProductHubToggles.ENABLE_SHOWING_NEW_PRODUCT_CARDS);

	useEffect(() => {
		getUserTenant().then((tenant) => {
			const mfaProduct = tenant.products?.find(
				({ type }) => type === ProductType.MOBILE_FLOW_ANALYZER
			);
			if (!mfaProduct?.planId) {
				return;
			}
			getProductPlanDisplayName(mfaProduct?.planId).then((mfaPlan) => {
				setPrimaryButtonMessageData((prevData) => {
					return {
						...prevData,
						[ProductType.MOBILE_FLOW_ANALYZER]: mfaPlan.toLowerCase().includes('community')
							? 'Community edition'
							: ''
					};
				});
			});
		});
	}, []);

	const redirectIfNeeded = useCallback((): void => {
		const redirectionUrlParam = getProductToRedirectFromUrl();
		if (redirectionUrlParam === REDIRECT_TO_PRODUCT_VALUE.MOBILE_SDK) {
			history.push(getMobileSDKPath());
		} else if (redirectionUrlParam === REDIRECT_TO_PRODUCT_VALUE.MOBILE_FLOW_ANALYZER) {
			history.push(getFlowAnalyzerForMobilePath());
		}
	}, [history]);

	const checkForTokenValidity = useCallback(async (): Promise<void> => {
		setLoading(true);
		try {
			const productExpirationDetails = await getAllProductsExpirationDetails();
			setProductExpirationDetails(productExpirationDetails);
		} catch (err) {
			Logger.error('Error getting product activation links', err);
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		redirectIfNeeded();
		checkForTokenValidity();
	}, [redirectIfNeeded, checkForTokenValidity]);

	const productsList = useMemo(() => {
		return ProductCardsData.map((product: IProductCardData) => {
			let fullProduct: IProductCardData = { ...product };
			fullProduct.expirationDetails = productExpirationDetails?.get(fullProduct.productType);
			// each product that needs to be redirected using the history object and has a path,
			// the goToProductButtonOnClick should be handled here.
			if (!fullProduct.goToProductButtonOnClick && fullProduct.path) {
				fullProduct = {
					...fullProduct,
					goToProductButtonOnClick: () => history.push(fullProduct.path)
				};
			}

			fullProduct.primaryButtonMessage = primaryButtonMessageData?.[fullProduct.productType];
			return fullProduct;
		}).filter((product: IProductCardData) => {
			// show new products only if the toggles is on. The rest should be shown always
			return (
				(showNewProducts && newProducts.includes(product.productType)) ||
				!newProducts.includes(product.productType)
			);
		});
	}, [history, primaryButtonMessageData, productExpirationDetails, showNewProducts]);

	if (isLoading) {
		return (
			<div className="welcome-page">
				<EvSpinner />
			</div>
		);
	}

	return (
		<div className="welcome-page">
			<div className="welcome-page-content">
				<EvMainTitle className="main-welcome-title">Welcome to Evinced Product Hub!</EvMainTitle>
				<div className="welcome-sub-title">
					Evinced provides a comprehensive digital accessibility product suite for software
					development teams.
				</div>

				<EvSection className="cards-section">
					{productsList.map((product: IProductCardData) => (
						<ProductCard key={product.productType} {...product} />
					))}
				</EvSection>
			</div>
		</div>
	);
};
