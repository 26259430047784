import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { useTitle } from '@evinced-private/ui-common';

import { getPageTitle } from 'src/helpers/AppHelper';

import { InvitationActivationCard } from '../../components/invitation-activation-card/InvitationActivationCard';
import { getErrorMessage } from '../../helpers/ApiErrorHelper';
import {
	getInvitationTokenFromUrl,
	getProductToRedirectFromUrl,
	PRODUCT_TO_REDIRECT_PARAM
} from '../../helpers/InvitationTokenHelper';
import { getHomepagePath } from '../../helpers/RoutesHelper';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import { Logger } from '../../services/Logger';
import { hasTenant } from '../../services/TenantsService';
import {
	acceptInvitation,
	acceptOpenInvitation,
	getAllUserPendingInvitations,
	getOpenInvitationDetails,
	getOpenInvitationsDetails
} from '../../services/UserInvitationService';
import { IOpenInvitation } from '../../types/OpenInvitation';
import { INVITATION_TYPE, PendingInvitation } from '../../types/PendingInvitation';

import { removeOldDuplicates } from './InvitationActiationHelper';

import './InvitationActivationPage.scss';

export const InvitationActivationPage: FC = () => {
	const [loading, setLoading] = useState<boolean>(true);
	const [activationInProcess, setActivationInProcess] = useState<boolean>(false);
	const [error, setError] = useState(null);
	const [pendingInvitations, setPendingInvitations] = useState<PendingInvitation[]>([]);
	const [selectedInvitation, setSelectedInvitation] = useState<PendingInvitation>(null);
	const history = useHistory();
	const { updateUserTenant } = useUserTenant();
	const { setTitle } = useTitle();
	setTitle(getPageTitle(''), 'Invitation Activation');

	const acceptUserInvitation = useCallback(async (): Promise<void> => {
		setActivationInProcess(true);
		const { type, invitation } = selectedInvitation;
		try {
			if (type === INVITATION_TYPE.OPEN) {
				await acceptOpenInvitation(invitation.token);
			} else {
				await acceptInvitation(invitation.token);
			}
			const redirectionUrlParam = getProductToRedirectFromUrl();
			let redirectLink = getHomepagePath();
			if (redirectionUrlParam) {
				redirectLink += `?${PRODUCT_TO_REDIRECT_PARAM}=${redirectionUrlParam}`;
			}
			history.push(redirectLink);
		} catch (e) {
			const errorMsg = getErrorMessage(e);
			Logger.error(
				`InvitationActivationPage - Error when accepting invitation, type: ${type}`,
				errorMsg
			);
			setError(errorMsg);
		} finally {
			setActivationInProcess(false);
			await updateUserTenant(true);
		}
	}, [history, selectedInvitation, updateUserTenant]);

	const getOpenInvitations = useCallback(
		async (isTokenRelatedToEmailInvitations: boolean): Promise<IOpenInvitation[]> => {
			const openInvitationToken = getInvitationTokenFromUrl();
			try {
				const openInvitationsDetails = await getOpenInvitationsDetails();
				// if the token is related to an email invitation,
				// no need to check if its related to an open invitation
				if (!isTokenRelatedToEmailInvitations && openInvitationToken) {
					const openInvitationFromUrl = await getOpenInvitationDetails(openInvitationToken);
					if (!openInvitationsDetails.some((i) => i.tenantId === openInvitationFromUrl.tenantId)) {
						openInvitationsDetails.unshift(openInvitationFromUrl);
					}
				}
				return openInvitationsDetails;
			} catch (err) {
				Logger.info('Error getting open invitation details', err);
				return null;
			}
		},
		[]
	);

	const selectInitialRadioOption = useCallback((invitations: PendingInvitation[]): void => {
		// try to set the token from URL as the selected one
		// (if it exists and appears on the invitations list)
		const invitaitonTokenFromUrl = getInvitationTokenFromUrl();
		const invitationFromUrl = invitations.find(
			(i) => i.invitation.token === invitaitonTokenFromUrl
		);
		if (invitationFromUrl) {
			setSelectedInvitation(invitationFromUrl);
		} else {
			// set the first option selected by default
			setSelectedInvitation(invitations[0]);
		}
	}, []);

	const loadInvitationsData = useCallback(async (): Promise<void> => {
		try {
			const userHasTenant = await hasTenant();
			if (userHasTenant) {
				history.push(getHomepagePath());
				return;
			}
			const invitaitonTokenFromUrl = getInvitationTokenFromUrl();
			const emailInvitations = await getAllUserPendingInvitations();
			const isTokenRelatedToEmailInvitations = emailInvitations.some(
				(i) => i.token === invitaitonTokenFromUrl
			);
			const openInvitations = await getOpenInvitations(isTokenRelatedToEmailInvitations);
			const latestInvitationPerTenant = removeOldDuplicates(emailInvitations, openInvitations);
			if (latestInvitationPerTenant.length === 0) {
				Logger.error('Got to the invitations page but there are no invitations');
				history.push(getHomepagePath());
				return;
			}
			setPendingInvitations(latestInvitationPerTenant);
			selectInitialRadioOption(latestInvitationPerTenant);
		} catch (e) {
			Logger.error('InvitationActivationPage Error loading invitations data', e);
			const errorMsg = getErrorMessage(e);
			setError(errorMsg);
		} finally {
			setLoading(false);
		}
	}, [getOpenInvitations, selectInitialRadioOption, history]);

	useEffect(() => {
		loadInvitationsData();
	}, [loadInvitationsData]);

	return (
		<InvitationActivationCard
			acceptInvitation={acceptUserInvitation}
			activationInProcess={activationInProcess}
			loading={loading}
			pendingInvitations={pendingInvitations}
			selectedInvitation={selectedInvitation}
			setSelectedInvitation={setSelectedInvitation}
			error={error}
			className="invitation-activation-page"
		/>
	);
};
