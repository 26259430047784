import { IUser, IUserToTenant } from '../types/User';

import { API_URL_PATHS } from './server/ApiUrlPaths';
import { apiCall } from './server/ServerApi';

const { USER_URL_PATH, USERS_URL_PATH } = API_URL_PATHS;

export async function getUserMetadata(): Promise<IUser> {
	return apiCall('GET', USER_URL_PATH);
}

export async function updateUserData(userData: IUserToTenant): Promise<IUserToTenant> {
	const url = `${USERS_URL_PATH}/${userData.id}`;
	const response = await apiCall('PUT', url, { data: userData });
	return response;
}

export async function deactivateUser(userId: string): Promise<void> {
	const url = `${USERS_URL_PATH}/${userId}`;
	return apiCall('DELETE', url, { data: userId });
}

export const resetPassword = async (userEmail: string): Promise<void> => {
	return apiCall(
		'POST',
		`https://${process.env.AUTH0_EVINCED_DOMAIN}/dbconnections/change_password`,
		{
			noExtraHeaders: true,
			isAbsoluteUrl: true,
			data: {
				client_id: process.env.AUTH0_CLIENT_ID,
				email: userEmail,
				connection: 'Username-Password-Authentication'
			}
		}
	);
};
