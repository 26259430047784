import { DropdownOptionType, IDropdownMenuOption } from '@evinced-private/ui-common';

type getEvincedAppsMenuOptionsHelperArgs = {
	hasMfaLicense: boolean;
	hasMsdkLicense: boolean;
	hasSiteScannerLicense: boolean;
	hasWebSdkLicense: boolean;
	hasPlatformLicense: boolean;
};

export const getEvincedAppsDropdownMenuOptions = (
	licenses: getEvincedAppsMenuOptionsHelperArgs
): IDropdownMenuOption[] => {
	const {
		hasMfaLicense,
		hasMsdkLicense,
		hasSiteScannerLicense,
		hasWebSdkLicense,
		hasPlatformLicense
	} = licenses;
	const defaultProps = {
		type: DropdownOptionType.LINK,
		hasBottomBorder: false,
		openInNewTab: true
	};

	const appsList = [
		{
			id: 'mfa-dashboard-url',
			url: process.env.MFA_DASHBOARD_URL,
			title: 'Mobile Flow Analyzer',
			active: false,
			hasLicense: hasMfaLicense,
			...defaultProps
		},
		{
			id: 'mobile-sdk-dashboard-url',
			url: process.env.MOBILE_SDK_DASHBOARD_URL,
			title: 'Mobile SDKs',
			active: false,
			hasLicense: hasMsdkLicense,
			...defaultProps
		},
		{
			id: 'web-sdk-dashboard-url',
			url: process.env.WEB_SDK,
			title: 'Web SDKs',
			active: false,
			hasLicense: hasWebSdkLicense,
			...defaultProps
		},
		{
			id: 'wfa-dashboard-url',
			url: process.env.WFA_DASHBOARD_URL,
			title: 'Web Flow Analyzer',
			active: false,
			hasLicense: hasPlatformLicense,
			...defaultProps
		},
		{
			id: 'site-scanner-url',
			url: process.env.SITE_SCANNER_URL,
			title: 'Site Scanner',
			active: false,
			hasLicense: hasSiteScannerLicense,
			...defaultProps
		},
		{
			id: 'product-hub',
			url: process.env.PRODUCT_HUB_URL,
			title: 'Product Hub',
			active: true,
			hasLicense: true,
			...defaultProps
		}
	];

	return appsList.filter((app) => app.hasLicense);
};
