import React, { createContext, FC, useContext, useMemo, useState } from 'react';

import { IEvBreadcrumb } from '@evinced-private/ui-common';

interface INavigation {
	currentBreadcrumbs: IEvBreadcrumb[];
	setCurrentBreadcrumbs: (breadcrumbs: IEvBreadcrumb[]) => void;
}

const NavigationContext = createContext<INavigation>(null);

const NavigationProvider: FC = ({ children }) => {
	const [currentBreadcrumbs, setCurrentBreadcrumbs] = useState<IEvBreadcrumb[]>(null);
	const value = useMemo(
		() => ({ currentBreadcrumbs, setCurrentBreadcrumbs }),
		[currentBreadcrumbs]
	);
	return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};

const useNavigation = (): INavigation => useContext(NavigationContext);

export { NavigationProvider, useNavigation };
