import { INVITATION_STATE } from './UserInvitationState';

enum ROLE_TYPE_ENTITY {
	TENANT_ADMIN = 'TENANT_ADMIN',
	READ_ONLY = 'READ_ONLY',
	TENANT_USER = 'TENANT_USER'
}

export const ROLE_TYPE_LABELS = {
	[ROLE_TYPE_ENTITY.TENANT_ADMIN]: 'Admin',
	[ROLE_TYPE_ENTITY.READ_ONLY]: 'Read-only',
	[ROLE_TYPE_ENTITY.TENANT_USER]: 'User'
};

export interface IUserInvitation {
	id: string;
	createdTime: Date;
	ownerId: string;
	tenantName: string;
	ownerEmail: string;
	tenantId: string;
	targetName: string;
	targetSurname: string;
	targetEmail: string;
	url: string;
	token: string;
	state: INVITATION_STATE;
	expirationTime: Date;
	activationTime: Date;
	roleType?: ROLE_TYPE_ENTITY;
}
