import React, { FC, useEffect, useMemo, useState } from 'react';

import { EvConfirm, EvStepper, Ttag } from '@evinced-private/ui-common';

import { PRODUCT_HUB_APP_ID } from '../../consts/dom-consts';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import { IServerErrors } from '../../services/server/ServerError';
import { createInvitation } from '../../services/UserInvitationService';
import { ProductHubPopup } from '../common/product-hub-popup/ProductHubPopup';

import { InviteUsers } from './bulk-invitations-steps/InviteUsers';
import { SetPermissions } from './bulk-invitations-steps/SetPermissions';

import './CreateUserInvitationPopup.scss';

interface ICreateUserInvitationPopupProps {
	isOpen: boolean;
	tenantId: string;
	onAfterBulkInvitation: (
		isFailed: boolean,
		announcementMsg: string,
		serverErrors?: IServerErrors[],
		successRate?: number
	) => void;
	onClose: () => void;
}

export const CreateUserInvitationPopup: FC<ICreateUserInvitationPopupProps> = ({
	isOpen = false,
	tenantId,
	onAfterBulkInvitation,
	onClose
}: ICreateUserInvitationPopupProps) => {
	const { tenant, usersRolesListDictionary } = useUserTenant();
	const roleType = usersRolesListDictionary.TENANT_USER;

	const [isLoading, setLoading] = useState<boolean>(true);
	const [emails, setEmails] = useState([]);
	const [bulkInviteError, setBulkInviteError] = useState(false);
	const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
	const [userPermission, setUserPermission] = useState<string>(roleType);

	const hasSiteScanner = useMemo((): boolean => {
		const products = tenant?.products || [];
		return products.some((product) => product.type === 'SCANNER');
	}, [tenant]);

	const handleBulkEmailChange = (res: { tags: Ttag[]; error: boolean }): void => {
		setEmails(res.tags);
		setBulkInviteError(res.error);
	};

	const isNextEnabled = useMemo(
		(): boolean => emails.length > 0 && !bulkInviteError,
		[emails, bulkInviteError]
	);

	const onPopupOpened = async (): Promise<void> => {
		setLoading(false);
	};

	const onSentBulkInvitation = async (): Promise<void> => {
		setLoading(true);
		let successInvitationsCounter = 0;
		const invitesPromiseList = [];
		const failedInvitationsList = [];
		emails.forEach((emailVal) => {
			const promise = createInvitation(emailVal.id, null, null, tenantId, userPermission)
				.then(() => {
					successInvitationsCounter += 1;
				})
				.catch((error) => {
					failedInvitationsList.push({ prefixMsg: emailVal.id, serverError: error });
				});
			invitesPromiseList.push(promise);
		});
		await Promise.all(invitesPromiseList);
		if (successInvitationsCounter === emails.length) {
			await onAfterBulkInvitation(false, 'Invitation(s) sent');
		} else {
			await onAfterBulkInvitation(
				true,
				'Invites failed to send',
				failedInvitationsList,
				emails.length - failedInvitationsList.length
			);
		}
		setLoading(false);
	};

	// reset state when popup closes
	useEffect(() => {
		if (!isOpen) {
			setEmails([]);
			setBulkInviteError(false);
			setUserPermission(roleType);
		}
	}, [isOpen, roleType]);

	const renderBulkInvitation = (): JSX.Element => {
		const steps = [
			{
				title: 'Invite users',
				component: (
					<InviteUsers
						handleChange={handleBulkEmailChange}
						isNextEnabled={isNextEnabled}
						isLastStep={!hasSiteScanner}
						currentEmailslist={[...emails]}
						onContinue={onSentBulkInvitation}
					/>
				)
			}
		];
		if (hasSiteScanner) {
			steps.push({
				title: 'Site Scanner Permissions',
				component: (
					<SetPermissions
						handleChange={setUserPermission}
						userPermission={userPermission}
						onContinue={onSentBulkInvitation}
					/>
				)
			});
		}

		return (
			<EvStepper
				steps={steps}
				onCloseCallback={() => setConfirmModalOpen(true)}
				className="bulk-invitation-wizard"
				headerClassName="bulk-invitation-wizard-header"
				titleClassName="bulk-invitation-wizard-title"
			/>
		);
	};

	return (
		<>
			<ProductHubPopup
				isControlled
				isOpen={isOpen}
				isCentered
				onClose={() => setConfirmModalOpen(true)}
				onOpen={onPopupOpened}
				title="Invite Users"
				className="create-user-invitation-popup"
				plainModalClassName="bulk-invitation"
				popupWidth={645}
				isPopupLoading={isLoading}
				plainModal
			>
				{renderBulkInvitation()}
			</ProductHubPopup>
			<EvConfirm
				appElement={PRODUCT_HUB_APP_ID}
				title="Confirm close"
				approveButtonText="Confirm"
				promptMessage="You have unsaved changes, are you sure you want to go back?"
				isOpen={isConfirmModalOpen}
				isControlled={true}
				onConfirm={(closeModal) => {
					onClose();
					closeModal();
				}}
				onClose={() => {
					setConfirmModalOpen(false);
				}}
			/>
		</>
	);
};
