import { FC } from 'react';
import { useHistory } from 'react-router';

import { getInvitationTokenFromUrl } from '../../helpers/InvitationTokenHelper';
import { getInvitationActivationPage, getSignupPath } from '../../helpers/RoutesHelper';
import { Logger } from '../../services/Logger';
import { setRedirectToPath } from '../../services/LoginRedirectionService';

export const OpenInvitationRedirectionPage: FC = () => {
	const history = useHistory();

	const token = getInvitationTokenFromUrl();
	if (token) {
		setRedirectToPath(getInvitationActivationPage(token));
	} else {
		Logger.error('Got to open invitation redirection page, but there is no token in the URL');
	}
	history.push(getSignupPath());
	return null;
};
