import { useAuth0 } from '@auth0/auth0-react';

type returnedMethods = { logout; isAuthenticated; getAccessToken };

export const useUniversalLogin = (): returnedMethods => {
	const { logout, isAuthenticated, getAccessTokenSilently } = useAuth0();

	const logoutMethod = (): void => {
		if (isAuthenticated) {
			logout();
		}
	};

	const isAuthenticatedMethod = (): boolean => isAuthenticated;

	const getAccessToken = async (): Promise<string> => {
		const accessToken = await getAccessTokenSilently({
			authorizationParams: {
				audience: process.env.AUTH0_EVINCED_API_AUDIENCE,
				scope: 'read:current_user'
			}
		});
		return accessToken;
	};

	return {
		logout: logoutMethod,
		isAuthenticated: isAuthenticatedMethod,
		getAccessToken
	};
};
