import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router';

import classNames from 'classnames';

import {
	EvButton,
	EvCard,
	EvLink,
	EvRadioGroup,
	EvSection,
	EvSpinner
} from '@evinced-private/ui-common';

import { useConfiguration } from 'src/providers/configurationProvider/ConfigurationProvider';

import { getTenantCreationPage } from '../../helpers/RoutesHelper';
import { ProductHubToggles } from '../../services/LocalTogglesService';
import { INVITATION_TYPE, PendingInvitation } from '../../types/PendingInvitation';
import { IUserInvitation } from '../../types/UserInvitation';
import { FullPageError } from '../full-page-error/FullPageError';

import './InvitationActivationCard.scss';

interface IInvitationActivationPageProps {
	acceptInvitation: () => Promise<void>;
	activationInProcess: boolean;
	loading: boolean;
	pendingInvitations: PendingInvitation[];
	selectedInvitation: PendingInvitation;
	setSelectedInvitation: (invitation: PendingInvitation) => void;
	className: string;
	error?: boolean;
}

const connectAccountText = 'Connect Account';
export const InvitationActivationCard: FC<IInvitationActivationPageProps> = ({
	acceptInvitation,
	activationInProcess,
	loading,
	pendingInvitations,
	selectedInvitation,
	setSelectedInvitation,
	error,
	className
}: IInvitationActivationPageProps) => {
	const history = useHistory();
	const { getToggle } = useConfiguration();

	const canCreateTenant = getToggle(ProductHubToggles.ENABLE_CREATE_TENANT);

	const renderInviterDetails = useCallback((pendingInvitation: PendingInvitation): JSX.Element => {
		const { type, invitation } = pendingInvitation;
		return (
			<div className="inviter-details">
				<div className="tenant-name">{invitation.tenantName}</div>
				{type === INVITATION_TYPE.EMAIL && (
					<div className="inviter-email">
						(Invited by {(invitation as IUserInvitation).ownerEmail})
					</div>
				)}
			</div>
		);
	}, []);

	const renderContent = useCallback((): JSX.Element => {
		if (!pendingInvitations || pendingInvitations.length === 0) {
			return null;
		}
		if (pendingInvitations.length === 1) {
			return (
				<div className="single-invitation-mode">
					<div className="upper-text">Your user will be connected to the following account:</div>
					<div className="inviter-info">{renderInviterDetails(pendingInvitations[0])}</div>
				</div>
			);
		}
		const radioOptions = pendingInvitations.map((i) => {
			const { invitation } = i;
			return {
				label: invitation.token,
				isChecked: selectedInvitation.invitation.token === invitation.token,
				onClick: () => setSelectedInvitation(i),
				component: renderInviterDetails(i)
			};
		});
		return (
			<div className="multiple-invitations-mode">
				<div className="inviter-info">
					<EvRadioGroup
						title="Please select to which account your user will be connected:"
						options={radioOptions}
						ariaLabel="Account to which the user will be connected"
					/>
				</div>
			</div>
		);
	}, [pendingInvitations, selectedInvitation, setSelectedInvitation, renderInviterDetails]);

	const getActionButtons = (): JSX.Element => {
		return (
			<div className="invitation-activation-footer">
				<EvButton
					title={connectAccountText}
					onClick={acceptInvitation}
					className="connect-account-btn"
					loading={activationInProcess}
				>
					{connectAccountText}
				</EvButton>
				{canCreateTenant && (
					<EvLink
						url="#"
						linkText="Connect your user to a new account"
						onClick={() => history.push(getTenantCreationPage())}
						className="open-account-creation-link"
					/>
				)}
			</div>
		);
	};

	if (loading) {
		return <EvSpinner />;
	}

	if (error) {
		return <FullPageError title="Could not activate your account" />;
	}

	return (
		<div className={classNames('invitation-activation-card-container', className)}>
			<EvCard
				title="Welcome to Evinced!"
				footer={getActionButtons()}
				className="invitation-activation-card"
				headingLevel={1}
			>
				<EvSection ariaLabel="main-section" className="invitation-activation-info">
					{renderContent()}
				</EvSection>
			</EvCard>
		</div>
	);
};
