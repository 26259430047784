import { ITenant, ProductType } from '../types/Tenant';
import { ITenantDescriptor } from '../types/TenantDescriptor';

import { API_URL_PATHS } from './server/ApiUrlPaths';
import { apiCall } from './server/ServerApi';

const { TENANTS_URL_PATH, DESCRIPTORS_URL_PATH } = API_URL_PATHS;

export enum TenantPlan {
	FREE_TRIAL = 'TRIAL',
	COMMUNITY = 'COMMUNITY'
}

export async function getUserTenant(): Promise<ITenant> {
	const tenants: ITenant[] = await apiCall('GET', TENANTS_URL_PATH);
	if (!tenants || tenants.length === 0) {
		return null;
	}
	return tenants[0];
}

export async function createTenant(
	companyName: string,
	tenantName: string,
	plan: TenantPlan
): Promise<void> {
	const reqBody: ITenant = {
		name: tenantName,
		accountPlan: plan,
		companyName
	};

	return apiCall('POST', TENANTS_URL_PATH, { data: reqBody });
}

export const TenantDescriptorValue = {
	TRIAL: 'TRIAL',
	COMMUNITY: 'COMMUNITY'
};

export const getTenantDescriptor = async (): Promise<ITenantDescriptor> => {
	return apiCall('GET', `${DESCRIPTORS_URL_PATH}/tenant`);
};

export const activateProduct = async (
	tenantId: string,
	productType: ProductType
): Promise<void> => {
	const body = {
		type: productType,
		tenantId
	};
	return apiCall('POST', `${TENANTS_URL_PATH}/${tenantId}/products`, {
		data: body
	});
};

export const hasAccessToProduct = async (productType: ProductType): Promise<boolean> => {
	const tenant = await getUserTenant();
	if (!tenant) {
		return false;
	}
	const productPermissions = tenant.products?.find((p) => p.type === productType);
	return !!productPermissions;
};

export type TenantExpirationDetails = {
	date: Date;
	isExpired: boolean;
	isLicensedForever: boolean;
};

export const getAllProductsExpirationDetails = async (): Promise<
	Map<ProductType, TenantExpirationDetails>
> => {
	const tenant = await getUserTenant();
	if (!tenant) {
		return null;
	}
	const result: Map<ProductType, TenantExpirationDetails> = new Map();

	// eslint-disable-next-line no-unused-expressions
	tenant.products?.forEach((productDetails) => {
		/**
		 * There are cases in which we don't have an expiration
		 * date in trial plan, meaning that we do not want to limit
		 * the account (product decision)
		 */
		const expirationDate = productDetails.expirationTime
			? new Date(productDetails.expirationTime)
			: null;
		const details: TenantExpirationDetails = {
			date: expirationDate,
			isExpired: expirationDate ? expirationDate < new Date() : false,
			isLicensedForever: expirationDate === null
		};
		result.set(productDetails.type, details);
	});
	// following Gilad's decision, since we don't have a separate license for Dev Debugger,
	// its expiration details should arise from the WFA (since currently both of the products
	// come as a one package).
	const wfaProductDetails = result.get(ProductType.WEB_FLOW_ANALYZER);
	result.set(ProductType.DEVELOPER_ACCESSIBILITY_DEBUGGER, wfaProductDetails);
	return result;
};

export const getProductExpirationDetails = async (
	type: ProductType
): Promise<TenantExpirationDetails> => {
	const allProductsDetails = await getAllProductsExpirationDetails();
	return allProductsDetails.get(type);
};

export const hasTenant = async (): Promise<boolean> => {
	const tenant = await getUserTenant();
	return !!tenant;
};
