import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

import classNames from 'classnames';

import { BUTTON_TYPES, EvButton, EvSpinner } from '@evinced-private/ui-common';

import { IUserToTenant } from 'src/types/User';
import { IUserInvitation } from 'src/types/UserInvitation';

import { CsvHeader, getCsvSeparator, getReportsCsvData } from '../../../app-tools/csv-wizard';

import './DownloadCSV.scss';

type DataType = IUserToTenant[] | IUserInvitation[];
interface IDownloadCSV {
	csvFileName: string;
	reportHeaders: CsvHeader[];
	data: DataType;
	sortByColumn: string;
	initDataOnClick?: () => Promise<void>;
	formatDateColumns?: string[];
	formatters?: { [key: string]: (value: IUserToTenant | IUserInvitation) => string };
}

export const DownloadCSV: FC<IDownloadCSV> = ({
	csvFileName,
	reportHeaders,
	data,
	sortByColumn,
	initDataOnClick,
	formatDateColumns,
	formatters
}): JSX.Element => {
	const csvLink = useRef<{ link: HTMLAnchorElement }>(null);
	const [initializing, setInitializing] = useState<boolean>(false);
	const [csvData, setCsvData] = useState(null);

	const clickDownloadLink = useCallback(() => {
		if (csvLink?.current?.link) {
			csvLink.current.link.click();
		}
	}, [csvLink]);

	useEffect(() => {
		if (!initializing) {
			clickDownloadLink();
		}
	}, [initializing, clickDownloadLink]);

	useEffect(() => {
		if (data) {
			setCsvData(
				getReportsCsvData(data, reportHeaders, sortByColumn, {
					formatDate: formatDateColumns,
					formatters
				})
			);
		}
	}, [data, setCsvData, reportHeaders, sortByColumn, formatDateColumns, formatters]);

	const onDownloadCsvClicked = useCallback(async (): Promise<void> => {
		if (initDataOnClick) {
			setInitializing(true);
			await initDataOnClick();
			setInitializing(false);
		} else {
			clickDownloadLink();
		}
	}, [initDataOnClick, clickDownloadLink]);

	return (
		<>
			<EvButton
				disabled={initializing}
				type={BUTTON_TYPES.ACTION}
				onClick={onDownloadCsvClicked}
				title="Download CSV"
			>
				{initializing ? <EvSpinner small /> : ''}
				<span className={classNames('download-csv', { downloading: initializing })}>
					Download CSV
				</span>
			</EvButton>
			{csvData && (
				<CSVLink
					style={{ display: 'none' }}
					ref={csvLink}
					headers={reportHeaders}
					data={csvData}
					separator={getCsvSeparator()}
					filename={csvFileName}
					tabIndex={-1}
				/>
			)}
		</>
	);
};
