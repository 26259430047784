import {
	getInvitationActivationPage,
	getInvitationRedirectionPath,
	getLoginPath,
	getLoginSuccessPath,
	getOpenInvitationRedirectionPath,
	getSignupPath,
	getTenantCreationPage,
	getUnauthorized403Page
} from '../../helpers/RoutesHelper';

export const navigationBreadcrumbsToFilter = [
	'invitation-activation',
	getUnauthorized403Page(),
	getTenantCreationPage(),
	getOpenInvitationRedirectionPath(),
	getInvitationRedirectionPath(),
	getInvitationActivationPage(),
	getSignupPath(),
	getLoginSuccessPath(),
	getLoginPath()
];

/**
 * This title mapping was created because Eyal's request to change the title of those
 * two routes but not to change the route itself.
 */
export const navigationTitleMapping = {
	'web-sdk': 'Automation for Web',
	'mobile-sdk': 'Automation for Mobile',
	'': 'All products'
};
