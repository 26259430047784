import React, {
	createContext,
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';

import { getToggleFromLocalStorage, ProductHubToggles } from 'src/services/LocalTogglesService';

import { isLoggedIn } from '../../services/AuthenticationService';
import {
	getCentralConfig,
	TConfiguration
} from '../../services/central-configuration/CentralConfigurationService';
import { useUserTenant } from '../userTenantProvider/UserTenantProvider';

type TGetToggle = (
	toggleId: ProductHubToggles,
	// useUserToggle: bc for auth0 use toggle support. Default is false
	// If true, will try to extract auth0 value toggle from the user's token
	// If false, will try to extract the value from the central configuration instead
	useUserToggle?: boolean
) => boolean;

interface IConfigurationContext {
	configuration: TConfiguration;
	getToggle: TGetToggle;
}

const ConfigurationContext = createContext<IConfigurationContext>(null);

const normalizeBooleanToggleValue = (toggle: string | boolean): string | boolean => {
	if (toggle === 'true') {
		return true;
	}
	if (toggle === 'false') {
		return false;
	}
	return toggle;
};

const ConfigurationProvider: FC = ({ children }) => {
	const [configuration, setConfiguration] = useState<TConfiguration>(null);
	const { tenant } = useUserTenant();

	const getToggle = useCallback(
		// turn `useAuth0Toggles` to false to make it use central configuration
		(toggleId: ProductHubToggles): boolean => {
			const localToggleValue = getToggleFromLocalStorage(toggleId);
			return (
				localToggleValue ||
				!!normalizeBooleanToggleValue(configuration?.TOGGLES?.[toggleId] as string | boolean)
			);
		},
		[configuration]
	);

	const initConfiguration = useCallback(async (): Promise<void> => {
		if (!configuration && tenant?.products) {
			const formattedConfig: TConfiguration = await getCentralConfig(tenant.products);
			setConfiguration(formattedConfig);
		}
	}, [configuration, tenant?.products]);

	useEffect(() => {
		if (isLoggedIn()) {
			initConfiguration();
		}
	}, [initConfiguration]);

	const value = useMemo(() => ({ configuration, getToggle }), [configuration, getToggle]);

	return <ConfigurationContext.Provider value={value}>{children}</ConfigurationContext.Provider>;
};

const useConfiguration = (): IConfigurationContext => useContext(ConfigurationContext);

export { ConfigurationProvider, useConfiguration };
