import React, { FC } from 'react';

import { EvConfirm, TOAST_STATUS } from '@evinced-private/ui-common';

import { PRODUCT_HUB_APP_ID } from '../../consts/dom-consts';
import { useNotifications } from '../../providers/notificationsProvider/NotificationsConsumer';
import { Logger } from '../../services/Logger';
import { resetPassword } from '../../services/UserService';

interface IResetPasswordPopupProps {
	userEmail: string;
	isOpen: boolean;
	closePopup: () => void;
}

const successMsg = 'Reset password link was sent successfully. Please check your inbox.';
const failedMsg = 'Reset password link couldn’t be sent. Please contact Evinced Support team.';
const promptMessage = 'A password reset link will be sent to: ';
const popupTitle = 'Reset your password?';

export const ResetPasswordPopup: FC<IResetPasswordPopupProps> = ({
	userEmail,
	isOpen,
	closePopup
}) => {
	const notificationsContext = useNotifications();
	const [sendingEmail, setSendingEmail] = React.useState(false);

	const onConfirm = async (closeModal): Promise<void> => {
		try {
			setSendingEmail(true);
			await resetPassword(userEmail);
			setSendingEmail(false);
			notificationsContext.toast({
				show: true,
				status: TOAST_STATUS.SUCCESS,
				announcement: successMsg
			});
		} catch (e) {
			Logger.error(failedMsg, e);
			notificationsContext.toast({
				show: true,
				status: TOAST_STATUS.FAIL,
				announcement: failedMsg
			});
		}
		closePopup();
		closeModal();
	};

	return (
		<EvConfirm
			title={popupTitle}
			appElement={PRODUCT_HUB_APP_ID}
			promptMessage={`${promptMessage}${userEmail}`}
			onConfirm={onConfirm}
			onClose={closePopup}
			disabled={sendingEmail}
			isControlled
			isOpen={isOpen}
			isPopupLoading={sendingEmail}
		/>
	);
};
