import React, { FC } from 'react';

import classNames from 'classnames';

import {
	BUTTON_SIZES,
	BUTTON_TYPES,
	EvButton,
	EvCard,
	EvContactUsButton
} from '@evinced-private/ui-common';

import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import { hubspotAPIKey } from '../../services/analytics/HubspotService';
import { TenantExpirationDetails } from '../../services/TenantsService';
import { formatDate } from '../common/helpers/DateFormatHelper';

interface IProductCardProps {
	cardTitle: string;
	contentText: string;
	learnMoreLink: string;
	learnMoreButtonTitle: string;
	learnMoreButtonText: string;
	goToProductButtonTitle: string;
	goToProductButtonText: string;
	goToProductButtonOnClick?: () => void;
	expirationDetails: TenantExpirationDetails;
	primaryButtonMessage?: string;
}
export const ProductCard: FC<IProductCardProps> = ({
	cardTitle,
	contentText,
	learnMoreLink,
	learnMoreButtonTitle,
	learnMoreButtonText,
	expirationDetails,
	goToProductButtonTitle,
	goToProductButtonText,
	goToProductButtonOnClick,
	primaryButtonMessage
}: IProductCardProps) => {
	const { user, tenant } = useUserTenant();
	const renderContactUsButton = (title: string): JSX.Element => (
		<EvContactUsButton
			portalId={hubspotAPIKey}
			formTitle={title}
			userEmail={user?.email}
			userTenant={tenant?.id}
			product={cardTitle}
		/>
	);
	const renderPrimaryButton = (): JSX.Element => {
		let mainButton;
		let footerText;

		// no product details - product was never activated
		if (!expirationDetails || !goToProductButtonOnClick) {
			mainButton = renderContactUsButton('Would you like to try it out?');
		} else if (expirationDetails.isExpired) {
			mainButton = renderContactUsButton('Would you like to renew your license?');
			footerText = 'Your license has expired';
		} else {
			mainButton = (
				<EvButton
					title={goToProductButtonTitle}
					type={BUTTON_TYPES.PRIMARY}
					onClick={() => goToProductButtonOnClick?.()}
					size={BUTTON_SIZES.BIG}
				>
					{goToProductButtonText}
				</EvButton>
			);

			if (expirationDetails.isLicensedForever) {
				footerText = primaryButtonMessage || 'Your license is active';
			} else if (expirationDetails.date) {
				footerText = (
					<>
						Your license will expire on <br /> {formatDate(expirationDetails.date)}
					</>
				);
			}
		}

		return (
			<div className="button-with-message">
				{mainButton}
				<span
					className={classNames('button-message', {
						error: expirationDetails?.isExpired
					})}
				>
					{footerText}
				</span>
			</div>
		);
	};

	const renderSecondaryButton = (): JSX.Element => {
		return (
			<EvButton
				title={learnMoreButtonTitle}
				type={BUTTON_TYPES.SECONDARY}
				onClick={() => window.open(learnMoreLink, '_blank')}
				size={BUTTON_SIZES.BIG}
			>
				{learnMoreButtonText}
			</EvButton>
		);
	};
	const renderFooter = (): JSX.Element => {
		return (
			<div className="welcome-screen-card-footer">
				{renderSecondaryButton()}
				{renderPrimaryButton()}
			</div>
		);
	};
	return (
		<EvCard title={cardTitle} footer={renderFooter()}>
			{contentText}
		</EvCard>
	);
};
