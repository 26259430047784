import React, { FC, useCallback } from 'react';

import {
	EvTable,
	IEvTableProps,
	SORT_ORDER,
	TableChangeState,
	TableChangeType
} from '@evinced-private/ui-common';

import isEqual from 'lodash/isEqual';

import {
	ITableSortOption,
	useTableState
} from '../../../providers/tableStateProvider/TableStateProvider';

type IProductHubTableProps = Omit<IEvTableProps, 'rootApplicationSelector'> & {
	tableId: string;
	isTableLoading?: boolean;
};

export const ProductHubTable: FC<IProductHubTableProps> = ({
	tableId,
	isTableLoading,
	...props
}: IProductHubTableProps) => {
	const { options } = props;

	const { getTableStateById, onTablePageOrPageSizeChange, onTableSort } = useTableState();
	const { paginationInfo, tableSort } = getTableStateById(tableId);

	const { page, sizePerPage } = paginationInfo;

	const onSort = useCallback(
		(sort: ITableSortOption[]): void => {
			onTableSort(tableId, sort);
		},
		[tableId, onTableSort]
	);

	const onPageChange = useCallback(
		(page, sizePerPage): void => {
			onTablePageOrPageSizeChange(tableId, { page, sizePerPage });
		},
		[tableId, onTablePageOrPageSizeChange]
	);

	const onSizePerPageChange = useCallback(
		(sizePerPage, page): void => {
			onTablePageOrPageSizeChange(tableId, { page, sizePerPage });
		},
		[tableId, onTablePageOrPageSizeChange]
	);

	const sort = tableSort || options.defaultSorted;
	if (options.remote) {
		options.remoteProps = {
			...options.remoteProps,
			onTableChange: (
				type: TableChangeType,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				{ page, sizePerPage, sortField, sortOrder }: TableChangeState<any>
			): void => {
				if (type === 'sort') {
					// the first time a table is loaded the onTableChange
					// function is called with the same sort
					// we want to ignore its changing on the page size
					const currentSort: ITableSortOption = sort?.[0] || { order: null, dataField: null };
					const newSort: ITableSortOption = {
						order: sortOrder === 'asc' ? SORT_ORDER.ASC : SORT_ORDER.DESC,
						dataField: sortField
					};
					const sortToSave = isEqual(currentSort, newSort) ? currentSort : newSort;
					onSort([sortToSave]);
				} else if (type === 'pagination') {
					onPageChange(page, sizePerPage);
				}
			},
			page,
			sizePerPage,
			totalSize: props.totalCount,
			sort
		};
		options.isLoading = isTableLoading;
	} else {
		options.localProps = {
			...options.localProps,
			defaultPage: page,
			defaultSizePerPage: sizePerPage,
			defaultSort: sort,
			onSort,
			onPageChange,
			onSizePerPageChange
		};
	}
	const newOptions = { ...options, defaultSorted: sort };

	return <EvTable key={tableId} {...props} options={newOptions} rootApplicationSelector=".app" />;
};
