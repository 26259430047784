const GA_LOCAL_STORAGE_KEY = 'GOOGLE_ANALYTICS_INFO';
const ACCESS_TOKEN_NAME = 'evincedAccessToken';
const ID_TOKEN_NAME = 'evincedIdToken';

export function getFromLocalStorage(key: string): string {
	return localStorage.getItem(key);
}

export function saveToLocalStorage(key: string, value): void {
	localStorage.setItem(key, value);
}

export function getUserFromLocalStorage(): { userId: string } {
	const gaUser = localStorage.getItem(GA_LOCAL_STORAGE_KEY);
	if (gaUser) {
		return JSON.parse(gaUser);
	}
	return null;
}

export function setGoogleAnalyticsUser(user: { userId: string }): void {
	localStorage.setItem(GA_LOCAL_STORAGE_KEY, JSON.stringify(user));
}

export function getAccessTokenFromLocalStorage(): string {
	return localStorage.getItem(ACCESS_TOKEN_NAME);
}

export function setAccessToken(token): void {
	localStorage.setItem(ACCESS_TOKEN_NAME, token);
}

export function getIdTokenFromLocalStorage(): string {
	return localStorage.getItem(ID_TOKEN_NAME);
}

export function setIdToken(token): void {
	localStorage.setItem(ID_TOKEN_NAME, token);
}

export function removeAuthenticationTokens(): void {
	localStorage.removeItem(ACCESS_TOKEN_NAME);
	localStorage.removeItem(ID_TOKEN_NAME);
}

export function removeValuesOnLogout(): void {
	removeAuthenticationTokens();
	localStorage.removeItem(GA_LOCAL_STORAGE_KEY);
}
