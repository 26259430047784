import React, { FC, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

import { EvLinkNavigationTabs } from '@evinced-private/ui-common';

import { getActiveUsers, getManageUserTabsViewPagePath } from '../../helpers/RoutesHelper';
import { withLimitedAccess } from '../../hoc/withLimitedAccess';
import { RegisteredUsersPage } from '../registered-users/RegisteredUsersPage';
import { UsersManagementPage } from '../users-management/UsersManagementPage';

import { MANAGE_AND_INVITE_USERS_TABS, USER_MANAGEMENT_PAGES } from './ManageUsersTabsViewConsts';

import './ManageUsersTabsView.scss';

const USER_PATHS = [getActiveUsers(), getManageUserTabsViewPagePath()];

const InviteAndManageUsersPage: FC = () => {
	const history = useHistory();
	const location = useLocation();

	const currentTabId = useMemo((): string => {
		return USER_PATHS.includes(location.pathname)
			? USER_MANAGEMENT_PAGES.ACTIVE_USERS
			: USER_MANAGEMENT_PAGES.PENDING;
	}, [location.pathname]);

	useEffect(() => {
		if (currentTabId === USER_MANAGEMENT_PAGES.ACTIVE_USERS) {
			history.push(getActiveUsers());
		}
	}, [currentTabId, history]);

	const renderTabPanelContent = useMemo((): JSX.Element => {
		if (currentTabId === USER_MANAGEMENT_PAGES.ACTIVE_USERS) {
			return <RegisteredUsersPage />;
		}
		return <UsersManagementPage />;
	}, [currentTabId]);

	return (
		<div className="invite-and-manage-users-page">
			<EvLinkNavigationTabs
				selectedTabId={currentTabId}
				options={MANAGE_AND_INVITE_USERS_TABS.map((page) => {
					return {
						onClick: () => history.push(page.route),
						title: page.label,
						id: page.key,
						url: page.route
					};
				})}
			/>
			{renderTabPanelContent}
		</div>
	);
};

export const ManageUsersTabsView = withLimitedAccess(InviteAndManageUsersPage);
