import React, { FC, useCallback, useMemo } from 'react';

import {
	BUTTON_TYPES,
	DropdownOptionType,
	EvDropdownMenu,
	EvIcon,
	EvTableColumn,
	IDropdownMenuOption,
	SORT_ORDER
} from '@evinced-private/ui-common';

import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import { IUserInvitation } from '../../types/UserInvitation';
import { INVITATION_STATE } from '../../types/UserInvitationState';
import { getInvitationsTableColumns } from '../common/helpers/InviteUsersHelper';
import { ProductHubTable } from '../common/product-hub-table/ProductHubTable';
import EllipsisIcon from '../icons/EllipsisIcon.svg';

interface IUserInvitationTableProps {
	invitations: IUserInvitation[];
	showInvitationPopup: (invitationId: string) => void;
	revokeInvitation: (invitationId: string) => void;
	filterText?: string;
}

const NaSign = 'N/A';

export const UsersInvitationsTable: FC<IUserInvitationTableProps> = ({
	invitations,
	filterText = null,
	showInvitationPopup,
	revokeInvitation
}: IUserInvitationTableProps) => {
	type InvitationsTableRow = {
		firstname: string;
		lastname: string;
		email: string;
		invitationId: string;
		createdTime: Date;
		status: INVITATION_STATE;
	};
	const { hasAdminPermissions, getFullRoleItem, usersRolesListDictionary } = useUserTenant();

	const roleType = usersRolesListDictionary.TENANT_USER;

	const tableRows = useMemo(() => {
		const filteredInvitations = filterText
			? invitations.filter(
					(invitation) =>
						invitation.targetName?.includes(filterText) ||
						invitation.targetSurname?.includes(filterText) ||
						invitation.targetEmail.includes(filterText)
					// eslint-disable-next-line no-mixed-spaces-and-tabs
				)
			: invitations;
		return filteredInvitations.map((i) => {
			return {
				firstname: i.targetName || NaSign,
				lastname: i.targetSurname || NaSign,
				email: i.targetEmail,
				invitationId: i.id,
				createdTime: i.createdTime,
				userType: i.roleType || roleType,
				status: i.state
			};
		});
	}, [invitations, filterText, roleType]);

	const renderActionsCell = useCallback(
		(cell, row: InvitationsTableRow): JSX.Element => {
			// show actions only for pending state
			if (row.status !== INVITATION_STATE.PENDING) {
				return null;
			}
			const options: IDropdownMenuOption[] = [
				{
					id: 'view-invitation',
					buttonProps: {
						type: BUTTON_TYPES.ICON,
						title: 'view invitation link',
						onClick: () => showInvitationPopup(row.invitationId),
						children: 'View invitation link'
					},
					type: DropdownOptionType.BUTTON
				}
			];

			if (hasAdminPermissions()) {
				options.push({
					id: 'delete-invitation',
					buttonProps: {
						type: BUTTON_TYPES.ICON,
						title: 'delete invitation',
						onClick: () => revokeInvitation(row.invitationId),
						children: 'Delete invitation'
					},
					type: DropdownOptionType.BUTTON
				});
			}

			return (
				<div className="actions-cell">
					<div className="actions-cell">
						<EvDropdownMenu
							id="invitations management"
							triggerButtonProps={{
								type: BUTTON_TYPES.ICON,
								ariaHaspopup: 'menu',
								title: `${row.email} invitation actions`,
								children: (
									<div className="menu-trigger-icon">
										<EvIcon icon={EllipsisIcon} small />
									</div>
								)
							}}
							options={options}
						/>
					</div>
				</div>
			);
		},
		[revokeInvitation, showInvitationPopup, hasAdminPermissions]
	);

	const tableColumns = useMemo((): EvTableColumn[] => {
		return [
			...getInvitationsTableColumns(getFullRoleItem),
			{
				dataField: 'actions',
				text: 'Actions',
				style: { width: '50px' },
				classes: 'actions-cell-td',
				headerStyle: { width: '50px' },
				formatter: renderActionsCell
			},
			{
				dataField: 'id',
				text: 'ID',
				hidden: true,
				formatter: (cell, row) => `${row.email}-${row.createdTime}`
			}
		];
	}, [renderActionsCell, getFullRoleItem]);

	return (
		<ProductHubTable
			tableId="user-invitations"
			tableData={tableRows}
			totalCount={tableRows.length}
			columns={tableColumns}
			options={{
				title: 'Invitations',
				pagination: true,
				dataType: 'invitations',
				caption: 'Invitations table',
				defaultSorted: [{ dataField: 'createdTime', order: SORT_ORDER.DESC }],
				keyField: 'id'
			}}
		/>
	);
};
