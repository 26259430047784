import React, { FC } from 'react';

import { EvCard, EvLink, EvMainTitle, EvSnippetView, useTitle } from '@evinced-private/ui-common';

import { getPageTitle } from 'src/helpers/AppHelper';

import { EXTERNAL_LINKS } from '../../helpers/ExternalLinks';

import './CodeAssistantPage.scss';

const installationScript = `
"github.copilot.advanced": {
    "debug.overrideProxyUrl": "http://code-assistant.evinced.com:8000",
    "debug.testOverrideProxyUrl": "http://code-assistant.evinced.com:8000"
}
`;

export const CodeAssistantPage: FC = () => {
	const { setTitle } = useTitle();
	setTitle(getPageTitle(''), 'Code Assistant');
	return (
		<div className="code-assistant-page">
			<div className="code-assistant-content">
				<EvMainTitle>Welcome to Code Assistant!</EvMainTitle>

				<EvCard className="code-assistant-card" title="Installation Instructions">
					<div className="code-assistant-instructions">
						<div className="instructions">
							Point your CoPilot extension to the Code Assistant server:
						</div>

						<EvSnippetView
							snippet={installationScript}
							codeType="html"
							codeClassName="script-container"
						/>
					</div>
				</EvCard>
				<div className="more-info">
					For a quick overview of how it works, please visit the&nbsp;
					<EvLink
						url={EXTERNAL_LINKS.LEARN_MORE_CODE_ASSISTANT_LINK}
						linkText="Code Assistant"
						openInNewTab
					/>
					&nbsp;page at Evinced.com.
				</div>
			</div>
		</div>
	);
};
