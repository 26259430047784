import React, { FC } from 'react';

import { EvIcon, EvTitle, NewEvincedLogo } from '@evinced-private/ui-common';

import './NotSupportedScreenSizePage.scss';

export const NotSupportedScreenSizePage: FC = () => {
	return (
		<div className="not-supported-screen-size-page">
			<div className="card">
				<EvIcon icon={NewEvincedLogo} className="evinced-logo" />
				<EvTitle titleText="Sorry, this device type is currently not supported." />
				<div className="main-text">
					This application is not optimized for mobile device resolutions and touch screens.
				</div>
				<a className="link-to-evinced" href="https://www.evinced.com/">
					Back to evinced.com website
				</a>
			</div>
		</div>
	);
};
