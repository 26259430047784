import React, { FC, useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';

import {
	BUTTON_SIZES,
	BUTTON_TYPES,
	EvButton,
	EvCard,
	EvContactUsButton,
	EvLink,
	EvMainTitle,
	EvSection,
	EvSpinner,
	useTitle
} from '@evinced-private/ui-common';

import { getPageTitle } from 'src/helpers/AppHelper';

import { EXTERNAL_LINKS } from '../../helpers/ExternalLinks';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import { hubspotAPIKey } from '../../services/analytics/HubspotService';
import { getMacDownloadLink, getWindowsDownloadLink } from '../../services/DownloadLinksService';
import { Logger } from '../../services/Logger';
import { FileHelper } from '../../services/server/FilesHelper';

import './MobileFlowAnalyzerPage.scss';

export const MobileFlowAnalyzerPage: FC = () => {
	const [isLoading, setLoading] = useState(true);
	const [isValid, setValid] = useState(false);
	const [macDownloadUrl, setMacDownloadUrl] = useState<string>('');
	const [windowsDownloadUrl, setWindowsDownloadUrl] = useState<string>('');
	const { setTitle } = useTitle();
	setTitle(getPageTitle(''), 'Mobile Flow Analyzer');
	const { user, tenant } = useUserTenant();
	const getDownloadLinks = useCallback(async (): Promise<void> => {
		setLoading(true);
		try {
			const macSignedUrl = await getMacDownloadLink();
			setMacDownloadUrl(macSignedUrl.url);
			const windowsSignedUrl = await getWindowsDownloadLink();
			setWindowsDownloadUrl(windowsSignedUrl.url);

			// check token
			setValid(true);
			// const serviceAccountExpiration = await getWindowsDownloadLink();
			// setValid(serviceAccountExpiration);
		} catch (err) {
			Logger.error('Error getting the download links');
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		getDownloadLinks();
	}, [getDownloadLinks]);

	if (isLoading) {
		return (
			<div className="mobile-flow-analyzer-page">
				<EvSpinner />
			</div>
		);
	}

	const renderDownloadMobileCardFooter = (): JSX.Element => {
		return (
			<div className="welcome-screen-card-footer">
				<div className="button-with-message">
					{!isValid && (
						<EvContactUsButton
							userEmail={user?.email}
							userTenant={tenant?.id}
							portalId={hubspotAPIKey}
							formTitle="Would you like to try it out?"
							product="Flow Analyzer for Mobile"
						/>
					)}
					{isValid && (
						<EvButton
							title="Download for Mac"
							type={BUTTON_TYPES.PRIMARY}
							onClick={() =>
								FileHelper.downloadFileFromUrl(macDownloadUrl, 'MobileFlowAnalyzer-mac')
							}
							size={BUTTON_SIZES.BIG}
						>
							Download for Mac
						</EvButton>
					)}
					<span className={classNames('button-message', { error: isValid })}>
						{!isValid && 'Not Valid'}
					</span>
				</div>
				<div className="button">
					<EvButton
						title="Download for Windows"
						type={BUTTON_TYPES.PRIMARY}
						onClick={() =>
							FileHelper.downloadFileFromUrl(windowsDownloadUrl, 'MobileFlowAnalyzer-windows')
						}
						size={BUTTON_SIZES.BIG}
					>
						Download for Windows
					</EvButton>
				</div>
			</div>
		);
	};

	return (
		<div className="mobile-flow-analyzer-page">
			<div className="mobile-flow-analyzer-page-content">
				<EvMainTitle>Welcome to Evinced Flow Analyzer for Mobile!</EvMainTitle>
				<EvSection className="cards-section">
					<EvCard
						className="mobile-automation-card"
						title="Download Flow Analyzer"
						footer={renderDownloadMobileCardFooter()}
					>
						Analyze accessibility issues in iOS and Android mobile applications directly from your
						desktop.
					</EvCard>
				</EvSection>
				<div className="more-info">
					For a quick overview of how it works, please visit the&nbsp;
					<EvLink
						url={EXTERNAL_LINKS.LEARN_MORE_MOBILE_ANALYZER_LINK}
						linkText="Flow Analyzer for Mobile"
						openInNewTab
					/>
					&nbsp;page at Evinced.com.
				</div>
			</div>
		</div>
	);
};
