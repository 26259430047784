import React, {
	createContext,
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';

import { isLoggedIn, isUserAuthProviderSaml } from '../../services/AuthenticationService';
import { getUserTenant } from '../../services/TenantsService';
import { getUserMetadata } from '../../services/UserService';
import { getRolesList } from '../../services/UsersService';
import { ITenant, ProductType, TenantProduct } from '../../types/Tenant';
import { IUser, RoleTypeItem } from '../../types/User';

interface IUsersRolesListDictionary {
	[key: string]: string;
}
interface IUserTenant {
	user: IUser;
	tenant: ITenant;
	hasAdminPermissions: () => boolean;
	updateUserTenant: (arg1?: boolean) => Promise<void>;
	usersRolesList: RoleTypeItem[];
	setUsersRolesList: (usersRolesList: RoleTypeItem[]) => void;
	getFullRoleItem: (role: string) => RoleTypeItem;
	usersRolesListDictionary: IUsersRolesListDictionary;
	getProductIdByType: (type: ProductType) => string;
}

const UserTenantContext = createContext<IUserTenant>(null);

export const UserTenantProvider: FC = ({ children }) => {
	const [user, setUser] = useState<IUser>(null);
	const [tenant, setTenant] = useState<ITenant>(null);
	const [usersRolesList, setUsersRolesList] = useState<RoleTypeItem[]>([]);
	const [usersRolesListDictionary, setusersRolesListDictionary] =
		useState<IUsersRolesListDictionary>({});

	const isUserLoading = useRef<boolean>(false);
	const isTenantLoading = useRef<boolean>(false);
	const isRolesLoading = useRef<boolean>(false);

	const getProductIdByType = useCallback(
		(productType: ProductType): string => {
			const product = tenant?.products?.find(
				(product: TenantProduct) => product.type === productType
			);
			return product?.id;
		},
		[tenant]
	);

	const getFullRoleItem = useCallback(
		(role: string): RoleTypeItem => {
			const roleItem = usersRolesList.find((item) => item.type === role);
			return roleItem;
		},
		[usersRolesList]
	);

	const updateUserTenant = useCallback(
		async (force = false): Promise<void> => {
			if (isLoggedIn()) {
				if ((!user || force) && !isUserLoading.current) {
					isUserLoading.current = true;
					const currentUser = await getUserMetadata();
					const isSSO = isUserAuthProviderSaml();
					setUser({ ...currentUser, isSSO });
					isUserLoading.current = false;
				}
				if ((!tenant || force) && !isTenantLoading.current) {
					isTenantLoading.current = true;
					const currentTenant = await getUserTenant();
					setTenant(currentTenant);
					isTenantLoading.current = false;
				}
				if ((!usersRolesList.length || force) && !isRolesLoading.current) {
					isRolesLoading.current = true;
					const usersRolesList = await getRolesList();
					setUsersRolesList(usersRolesList);
					isRolesLoading.current = false;
				}
			}
		},
		[user, tenant, usersRolesList]
	);

	const onLoad = useCallback(() => updateUserTenant(), [updateUserTenant]);

	const hasAdminPermissions = useCallback(
		(): boolean => user && user.role?.type === usersRolesListDictionary?.TENANT_ADMIN,
		[user, usersRolesListDictionary]
	);

	useEffect(() => {
		onLoad();
	}, [onLoad]);

	useEffect(() => {
		const typesListEnum = {};
		usersRolesList.forEach((role) => {
			typesListEnum[role.type] = role.type;
		});
		setusersRolesListDictionary(typesListEnum);
	}, [usersRolesList]);

	const value = useMemo(() => {
		return {
			user,
			tenant,
			hasAdminPermissions,
			updateUserTenant,
			usersRolesList,
			setUsersRolesList,
			getFullRoleItem,
			usersRolesListDictionary,
			getProductIdByType
		};
	}, [
		user,
		tenant,
		hasAdminPermissions,
		updateUserTenant,
		usersRolesList,
		getFullRoleItem,
		usersRolesListDictionary,
		getProductIdByType
	]);
	return <UserTenantContext.Provider value={value}>{children}</UserTenantContext.Provider>;
};

export const useUserTenant = (): IUserTenant => useContext(UserTenantContext);
