import React, { FC, useState } from 'react';

import { EvTextInput, EvTitle } from '@evinced-private/ui-common';

import { IUserToTenant } from 'src/types/User';

import './UserInfo.scss';

interface IUserInfoProps {
	user: IUserToTenant;
	onUserDataChange: (userData: IUserToTenant) => void;
}

export const UserInfo: FC<IUserInfoProps> = ({ user, onUserDataChange }) => {
	const [userData, setUserData] = useState<IUserToTenant>(user);

	const onUserInfoChange = (value: string, field: string): void => {
		const userData = { ...user };
		userData.user[field] = value;
		setUserData(userData);
		onUserDataChange(userData);
	};

	const renderRequiredFieldError = (field): string => {
		if (field) {
			return '';
		}
		return 'This field is required';
	};

	return (
		<div className="user-info">
			<EvTitle titleText="User Info" />
			<div className="user-info-form">
				<div className="form-field">
					<label>First Name</label>
					<EvTextInput
						ariaLabel="First Name"
						value={userData.user.name}
						isRequired
						error={renderRequiredFieldError(userData?.user.name)}
						onChange={(value) => onUserInfoChange(value, 'name')}
					/>
				</div>
				<div className="form-field">
					<label>Last Name</label>
					<EvTextInput
						ariaLabel="Last Name"
						isRequired
						error={renderRequiredFieldError(userData?.user.surname)}
						value={userData.user.surname}
						onChange={(value) => onUserInfoChange(value, 'surname')}
					/>
				</div>
				<div className="form-field">
					<label>Email</label>
					<EvTextInput
						ariaLabel="Email"
						value={userData.user.email}
						onChange={(value) => onUserInfoChange(value, 'email')}
						isDisabled={true}
					/>
				</div>
			</div>
		</div>
	);
};
