import React, { FC } from 'react';
import { Route, Router, Switch, useHistory } from 'react-router';

import { getEditUserPath, getManageUserTabsViewPagePath } from '../../helpers/RoutesHelper';
import { EditUserPage } from '../edit-user/EditUserPage';

import { ManageUsersTabsView } from './ManageUsersTabsView';

export const ManageUsersRouter: FC = () => {
	const history = useHistory();
	return (
		<Router history={history}>
			<Switch>
				<Route exact path={getEditUserPath(':id')} component={EditUserPage} />
				<Route path={getManageUserTabsViewPagePath()} component={ManageUsersTabsView} />
			</Switch>
		</Router>
	);
};
