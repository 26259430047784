import React, { FC, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { EvBreadcrumbs, IEvBreadcrumb } from '@evinced-private/ui-common';

import { getPathNavigationLabel } from '../../helpers/NavigationHelper';
import { getManageUserTabsViewPagePath, getNoTenantPath } from '../../helpers/RoutesHelper';
import { useNavigation } from '../../providers/navigationProvider/NavigationProvider';

export const HeaderNavigationBreadcrumbs: FC = () => {
	const location = useLocation();
	const { currentBreadcrumbs, setCurrentBreadcrumbs } = useNavigation();

	const path = location.pathname;
	// Split the path into individual segments
	const segments = useMemo(() => {
		const pathSegments = path
			.split('/')
			.filter((segment) => segment && !getNoTenantPath().includes(segment));
		return path.includes(getManageUserTabsViewPagePath())
			? pathSegments
			: // Start with an empty string which is mapped to the root path ('all products' breadcrumb).
				// Not needed for the users page, as its breadcrumb should not have a 'all products' parent.
				[''].concat(pathSegments);
	}, [path]);

	const routes: IEvBreadcrumb[] = useMemo(() => {
		return segments
			.map((segment, index) => ({
				id: segment,
				url: `/${segments.slice(0, index + 1).join('/')}`,
				title: getPathNavigationLabel(segment)
			}))
			.filter((segment) => segment.title);
	}, [segments]);

	useEffect(() => {
		setCurrentBreadcrumbs(routes);
	}, [routes, setCurrentBreadcrumbs]);

	const renderBreadcrumbs = (): JSX.Element => {
		if (!routes || !currentBreadcrumbs) {
			return null;
		}
		const breadcrumbs = currentBreadcrumbs || routes;
		return <EvBreadcrumbs routes={breadcrumbs} />;
	};

	return renderBreadcrumbs();
};
