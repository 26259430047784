import React, { FC, useEffect, useState } from 'react';

import { EvSection, EvTextInputSearch, useDebounce } from '@evinced-private/ui-common';

import './SearchFilter.scss';

interface ISearchFilter {
	searchInputValue: string;
	onInputChange: (value: string) => void;
	onEnter?: (value: string) => void;
}

export const SearchFilter: FC<ISearchFilter> = ({ searchInputValue, onInputChange, onEnter }) => {
	const [searchValue, setSearchValue] = useState(searchInputValue);
	const debouncedSearchValue = useDebounce(searchValue, 1000);

	useEffect(() => {
		setSearchValue(searchInputValue);
	}, [searchInputValue]);

	useEffect(() => {
		onInputChange(debouncedSearchValue);
	}, [onInputChange, debouncedSearchValue]);

	const onChange = (value: string): void => {
		setSearchValue(value);
	};

	return (
		<EvSection className="search-filter" ariaLabel="Search filter">
			<div className="search-input">
				<EvTextInputSearch
					value={searchValue}
					onChange={onChange}
					onConfirm={() => onEnter?.(searchValue)}
				/>
			</div>
		</EvSection>
	);
};
