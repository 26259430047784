import { setCurrentUser } from './analytics/AnalyticsService';
import { identifyUserByEmail, trackLogin } from './analytics/HubspotService';
import { getEvincedUserId, setTokens } from './AuthenticationService';
import { Logger } from './Logger';
import { getUserMetadata } from './UserService';

export async function initAppOnAuthentication(authResult): Promise<void> {
	setTokens(authResult);
	const evincedUserId = getEvincedUserId();
	setCurrentUser({ userId: evincedUserId || 'NO_USER_ID' });
	try {
		const userData = await getUserMetadata();
		identifyUserByEmail(userData.email);
		trackLogin();
	} catch (error) {
		Logger.error('Error getting user details, could not init hubspot API');
	}
}
