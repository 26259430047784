import { API_URL_PATHS } from './server/ApiUrlPaths';
import { apiCall } from './server/ServerApi';

const { DOWNLOAD_LINKS_URL_PATH } = API_URL_PATHS;

type SignedUrl = {
	url: string;
};

type DownloadLink = {
	downloadLink: string;
	type: string;
};

export async function getMacDownloadLink(): Promise<SignedUrl> {
	return apiCall('GET', `${DOWNLOAD_LINKS_URL_PATH}/mobile/desktop?operatingSystem=mac`);
}

export async function getWindowsDownloadLink(): Promise<SignedUrl> {
	return apiCall('GET', `${DOWNLOAD_LINKS_URL_PATH}/mobile/desktop?operatingSystem=windows`);
}

export async function getFlowAnalyzerForWebStoreLink(): Promise<DownloadLink> {
	return apiCall('GET', `${DOWNLOAD_LINKS_URL_PATH}/web/flow-analyzer`);
}

export async function getDevDebuggerStoreLink(): Promise<DownloadLink> {
	return apiCall('GET', `${DOWNLOAD_LINKS_URL_PATH}/web/dev-debugger`);
}
