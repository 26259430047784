/*

[
 {
    description: 'Enable creation of a new tenant instead of selecting one you are invited to',
    key: 'PRODUCT_HUB_UI/TOGGLES/ENABLE_CREATE_TENANT',
    exampleValue: true,
    defaultValue: false,
    schema: { type: 'boolean' }
  }, {
    description: 'Show cards for new products',
    key: 'PRODUCT_HUB_UI/TOGGLES/ENABLE_SHOWING_NEW_PRODUCT_CARDS',
    exampleValue: true,
    defaultValue: false,
    schema: { type: 'boolean' }
  }
]



*/

export enum ProductHubToggles {
	ENABLE_CREATE_TENANT = 'ENABLE_CREATE_TENANT',
	ENABLE_SHOWING_NEW_PRODUCT_CARDS = 'ENABLE_SHOWING_NEW_PRODUCT_CARDS'
}

export const TOGGLES_DETAILS = [
	{
		name: ProductHubToggles.ENABLE_CREATE_TENANT,
		label: 'Enable creation of a new tenant instead of selecting one you are invited to'
	},
	{
		name: ProductHubToggles.ENABLE_SHOWING_NEW_PRODUCT_CARDS,
		label: 'Show the new products: Design Assistant and Code Assistant'
	}
];

export const getToggleFromLocalStorage = (toggle: ProductHubToggles): boolean => {
	const value = localStorage.getItem(toggle);
	// cast the string value to it's boolean equivalent
	return value === 'true';
};

export const setToggle = (toggle: ProductHubToggles, value: string): void => {
	localStorage.setItem(toggle, value);
};

export const removeToggle = (toggle: ProductHubToggles): void => {
	localStorage.removeItem(toggle);
};
